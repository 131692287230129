<template>
  <EtNavbar>
    <template #title>
      <a
        :href="wwwLink"
        :title="t('layout.theNavigationBar.gotoEt')">
        <img
          alt="Ejendomstorvet Logo"
          :src="`${wwwLink}/static/img/logo.svg`" />
      </a>
    </template>
    <template #nav1items>
      <EtNavbarItem
        :href="`${wwwLink}/soeg`">
        Søg ejendom
      </EtNavbarItem>
      <EtNavbarItem
        :href="`${wwwLink}/finderhvervsmaegler`">
        Find mægler
      </EtNavbarItem>
      <EtNavbarItem
        href="https://www.ejendomstorvet.dk/nyheder/">
        Nyheder
      </EtNavbarItem>
      <EtNavbarItem
        :href="`${wwwLink}/statistik`">
        Statistik
      </EtNavbarItem>
      <EtNavbarItem
        href="https://www.ejendomstorvet.dk/info/omos">
        Om ejendomstorvet
      </EtNavbarItem>
    </template>

    <template #nav2items="{ closeMenu }">
      <EtNavbarItem
        v-if="userStore.isAuthenticated && userStore.hasUserRole('admin', 'member')"
        icon="add">
        <RouterLink
          :to="{ name: routeNames.ListingsCreate }"
          @click="closeMenu">
          {{ t('layout.theNavigationBar.newListing') }}
        </RouterLink>
      </EtNavbarItem>
      <EtNavbarItem
        v-if="!userStore.activeAccount && userStore.accounts.length <= 0"
        icon="add">
        <a :href="`${wwwLink}/annoncering`">
          Bliv annoncør
        </a>
      </EtNavbarItem>
      <EtNavbarItem icon="user">
        <a
          v-if="!userStore.isAuthenticated"
          @click="onLogin">
          {{ t('layout.theNavigationBar.login') }}
        </a>
        <RouterLink
          v-else
          :to="{ name: routeNames.UserProfileIndex }"
          :title="t('layout.theNavigationBar.loggedOnAs', { email: userStore.user?.email })"
          @click="closeMenu">
          {{ t('layout.theNavigationBar.loggedOn') }}
        </RouterLink>
      </EtNavbarItem>
    </template>

    <template
      v-if="userStore.isAuthenticated"
      #nav3items>
      <RouterLink
        v-for="child in computedLinks"
        v-slot="{ href, navigate, isExactActive }"
        :key="child.label"
        :to="{ name: child.routeName }"
        custom>
        <EtNavbarItem
          :is-active="isExactActive"
          :href="href"
          @click="navigate">
          {{ child.label }}
        </EtNavbarItem>
      </RouterLink>
      <div
        v-if="userStore.activeAccount"
        class="u-ml-a"
        :title="`Fakturerings nr.: ${userStore.activeAccount.billingId}`">
        {{ userStore.activeAccount.name }} - {{ userTitle }}
      </div>
    </template>
  </EtNavbar>
</template>

<script setup>
  // @ts-check
  import { EtNavbar, EtNavbarItem } from 'vue-ui-kit';
  import { useAuth } from '@common/composables/auth/authPlugin.js';
  import { RouterLink } from 'vue-router';
  import { routeNames } from '@/router';
  import { useT } from '@common/i18n/i18n.js';
  import { useUserStore } from '@common/store/index.js';
  import { computed } from 'vue';

  const t = useT();
  const userStore = useUserStore();
  const { login } = useAuth();

  const onLogin = () => {
    login({ fullPath: '/' });
  };

  const wwwLink = import.meta.env.VITE_PATH_WWW;

  /**
   * @type {{ label: string, routeName: string, allowedUserRoles?: ('admin'|'member'|'readonly')[], allowedPermissions?: string[], allowedAccountType?: number }[]}
   */
  const links = [{
    label: t('layout.theNavigationBar.listings', 2),
    routeName: routeNames.ListingsIndex,
    allowedUserRoles: ['admin', 'member', 'readonly'],
  }, {
    label: t('layout.theNavigationBar.accountStatistics'),
    routeName: routeNames.AccountStatistics,
    allowedUserRoles: ['admin', 'member', 'readonly'],
    allowedPermissions: ['accountStatistics'],
  }, {
    label: 'Udbud og omsætningsstatistik',
    routeName: routeNames.MarketStatistics,
    allowedUserRoles: ['admin', 'member', 'readonly'],
    allowedPermissions: ['marketStatistics'],
  }, {
    label: t('layout.theNavigationBar.account'),
    routeName: routeNames.AccountShow,
    allowedUserRoles: ['admin', 'member', 'readonly'],
  }, {
    label: t('layout.theNavigationBar.favorites'),
    routeName: routeNames.FavoritesPage,
  }, {
    label: t('layout.theNavigationBar.searchAgents'),
    routeName: routeNames.SearchAgentsPage,
  }, {
    label: 'Vejledninger',
    routeName: routeNames.ManualsListPage,
    allowedUserRoles: ['admin', 'member', 'readonly'],
    // allowedPermissions: ['manuals'],
    // allowedAccountType: 1,
  }];

  const userTitle = computed(() => {
    if (userStore.activeAccount) {
      return t(`layout.theNavigationBar.users.${userStore.activeAccount.accountType}`);
    }

    return t('layout.theNavigationBar.users.user');
  });

  const computedLinks = computed(() => {
    return links.filter((link) => {
      if (!link.allowedPermissions && !link.allowedUserRoles && !link.allowedAccountType) {
        return true;
      }

      if (userStore.accounts.length === 0) {
        return false;
      }

      let allowed = false;
      if (link.allowedUserRoles) {
        allowed = link.allowedUserRoles.some(role => userStore.hasUserRole(role));
      }

      if (allowed && link.allowedPermissions) {
        allowed = link.allowedPermissions.some(permission => userStore.hasPermission(permission));
      }

      if (allowed && link.allowedAccountType) {
        allowed = userStore.activeAccount?.accountType === link.allowedAccountType;
      }

      return allowed;
    });
  });
</script>
