// @ts-check
import { authGuard } from '@common/composables/auth/authGuard.js';
import { useUserStore } from '@common/store/index.js';
import { routeNames } from './index.js';

/**
 * @typedef {import('./private.js').EtRouteMetaModel} EtRouteMetaModel
 */

/**
 * @param {import('vue-router').RouteLocation & { meta: EtRouteMetaModel }} to Where to
 * @returns {Promise<boolean|object>} Returns bool or object that tells router to continue or not
 */
export const privateGuard = async (to) => {
  // If public stop here.
  if (to.meta.public) {
    return;
  }

  // 0. step. If state from Kinde, then just stop here because there will be a redirect in authPlugin on_redirect_callback
  if (to.query?.state != null) {
    return;
  }

  // 1. step. Is user authenticated at Kinde. If not, then goto login
  const result = await authGuard(to);
  if (result !== true) {
    return result;
  }

  const userStore = useUserStore();
  await userStore.whenReady();

  // 1a. step. Specifically for the account create page we want some logic to behave!
  if (to.name === routeNames.AccountCreate) {
    if (userStore.accounts.length > 0 && userStore.activeAccount && userStore.activeAccount?.ID == null) {
      return { name: routeNames.SelectAccountIndex };
    }

    if (userStore.accounts.length > 0 && userStore.activeAccount && userStore.activeAccount?.ID != null) {
      return { name: routeNames.ListingsCreate };
    }
  }

  // 1b. step. If route has none allwedUserRoles, then route is open to all logged in users
  if (to.meta.allowedUserRoles == null || to.meta.allowedUserRoles.length === 0) {
    return true;
  }

  if (to.name === routeNames.ListingsCreate) {
    if (userStore.accounts.length <= 0) {
      return { name: routeNames.AccountCreate };
    }
  }

  // 2. step. Only users with a single account or accounts to select from, should be allowed access
  if (userStore.accounts.length <= 0) {
    return { name: routeNames.NoAccess };
  }

  // 3. step. Has activeAccount selected?
  if ((userStore.activeAccount == null || userStore.activeAccount.ID == null)) {
    // We are on SelectAccountIndex, so return now
    if (to.name === routeNames.SelectAccountIndex) {
      return true;
    } else {
      return {
        name: routeNames.SelectAccountIndex,
        query: { redirect: to.fullPath },
      };
    }
  }

  // // 3a. activeAccount should be broker and not lightadvitiser
  // // 1 == broker, 2 == lightadvitiser
  // if (userStore.activeAccount.accountType !== 1) {
  //   return { name: routeNames.NoAccess, query: { types: true } };
  // }

  // 4. step. Check that route is not protected by user-role
  if (to.meta.allowedUserRoles) {
    const allowedUserRoles = to.meta.allowedUserRoles;
    if (!allowedUserRoles.some(a => userStore.hasUserRole(a))) {
      return { name: routeNames.NoAccess, query: { userroles: true } };
    }
  }

  // 5. step. Check that route is not protected by permissions
  if (to.meta.allowedPermissions) {
    const allowedPermissions = /** @type {string[]} */ (to.meta.allowedPermissions); // Cast
    if (!allowedPermissions.some(a => userStore.hasPermission(a))) {
      return { name: routeNames.NoAccess, query: { permissions: true } };
    }
  }

  return true;
};
